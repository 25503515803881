import React from "react";
import ReactDatePicker from "react-datepicker";
import idn from "date-fns/locale/id";
import clsx from "clsx";
import MaskedTextInput from "react-text-mask";
import { getMonth, getYear } from "date-fns";
import { HiChevronRight, HiChevronLeft } from "react-icons/hi";

const DatePicker = ({
  dateFormat = "dd/mm/yyyy",
  name,
  selected = null,
  className,
  errors,
  label,
  disabled,
  requiredLabel = false,
  errorMessage = "",
  onChange,
  isClearable = false,
}) => {
  const range = (from, until) =>
    Array.from(Array(until - from), (_, i) => i + from);
  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];
  const CustomHeader = (
    {
      date,
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
    },
    name
  ) => {
    return (
      <div className="flex gap-1 items-center w-full justify-center">
        <button
          type="button"
          formNoValidate
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <HiChevronLeft size={20} />
        </button>
        <select
          id={`month-${name}`}
          value={months[getMonth(date)]}
          onChange={({ target: { value } }) =>
            changeMonth(months.indexOf(value))
          }
          className="text-sm bg-transparent w-20 ml-6 inline-block"
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <input
          id={`year-${name}`}
          type="number"
          className="text-sm bg-transparent w-14"
          value={getYear(date)}
          onChange={({ target: { value } }) => changeYear(value)}
          min={1950}
          max={getYear(new Date()) + 30}
        />
        <button
          type="button"
          formNoValidate
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <HiChevronRight size={20} />
        </button>
      </div>
    );
  };
  return (
    <div className="input-group-date relative">
      <ReactDatePicker
        locale={idn}
        id={name}
        renderCustomHeader={(e) => CustomHeader(e, name)}
        wrapperClassName="w-full"
        dateFormat={dateFormat}
        className={clsx(
          "input-date",
          className,
          selected ? "has-value" : "",
          errors
            ? "border-[1.5px] border-red-500"
            : "border-[1.5px] border-gray-400",
          disabled ? "bg-gray-200" : "bg-transparent"
        )}
        selected={
          ![null, "", " ", "undefined"].includes(selected)
            ? new Date(selected)
            : null
        }
        autoComplete="off"
        placeholderText="dd/mm/yyyy"
        onChange={onChange}
        disabled={disabled}
        isClearable={isClearable}
        customInput={
          <MaskedTextInput
            type="text"
            required
            mask={
              [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] || null
            }
          />
        }
      />
      <label
        className={clsx(
          `input-date-label`,
          errors ? "text-red-500" : "text-gray-500",
          disabled
            ? ![null, "", " ", "undefined"].includes(selected)
              ? "bg-gradient-to-b from-white from-50% to-gray-200 to-50%"
              : "bg-transparent"
            : "bg-white"
        )}
        htmlFor={name}
      >
        {label || ""}
        {requiredLabel && <span className="text-red-500 ml-1">*</span>}
      </label>
      {errorMessage && (
        <p className="text-red-600 pt-[0.15rem]">{errorMessage}</p>
      )}
    </div>
  );
};

export default DatePicker;
