import React from "react";
import Router from "./Routes";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();
export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="relative">
        <Toaster
          containerStyle={{
            zIndex: 999999,
          }}
          toastOptions={{
            position: "top-right",
            success: {
              style: {
                background: "#22c55e",
                color: "white",
              },
              iconTheme: {
                primary: "white",
                secondary: "#22c55e",
              },
            },
            error: {
              style: {
                background: "#ef4444",
                color: "white",
              },
              iconTheme: {
                primary: "white",
                secondary: "#ef4444",
              },
            },
          }}
          gutter={15}
        />
      </div>
      <Router />
    </QueryClientProvider>
  );
}
