import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";

function Button({
  children,
  fullWidth,
  type = "button",
  padding = "px-3 py-2",
  danger,
  disabled,
  color,
  className,
  ...rest
}) {
  return (
    <button
      type={type}
      className={clsx(
        `
                    flex
                    justify-center
                    items-center
                    rounded-md
                    text-sm
                    cursor-pointer
                    font-medium
                    focus-visible:outline
                    focus-visible:outline-2
                    focus-visible:outline-offset-2                
                `,
        className,
        padding,
        disabled && `opacity-50 cursor-progress`,
        fullWidth && "w-full",
        color ? `${color}` : `text-white`,
        danger &&
          "bg-rose-500 hover:bg-rose-600 focus-visible:outline-rose-600",
        !danger &&
          !color &&
          "bg-[var(--color-primary)] hover:bg-blue-700 focus-visible:outline-blue-[var(--color-primary)]"
      )}
      formNoValidate
      {...rest}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  rest: PropTypes.any,
};

export default Button;
