import { lazy, Suspense } from "react";
import { Navigate, createBrowserRouter, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { RouterProvider } from "react-router-dom";
import PageRouteList from "./RouteList";
import { WaveLoading } from "./components";

const MainLayout = lazy(() => import("./layouts/mainLayout"));
const LoginPage = lazy(() => import("./pages/login"));
const ErrorPage = lazy(() => import("./pages/500"));
const NotFoundPage = lazy(() => import("./pages/404"));

export const RequireAuth = ({ children }) => {
  const { pathname } = useLocation();
  const token = Cookies.get("personalia_access_token");
  if (!token) {
    return <Navigate to="/login" state={{ prev: pathname }} />;
  }
  return children;
};

export default function PageRoutes() {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/",
      element: (
        <RequireAuth>
          <MainLayout />
        </RequireAuth>
      ),
      children: PageRouteList().RouteList,
      errorElement: <ErrorPage />,
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ]);
  return (
    <Suspense fallback={<WaveLoading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
