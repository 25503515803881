import { lazy } from "react";
const DashboardPage = lazy(() => import("./pages/dashboard"));
const StaffPage = lazy(() => import("./pages/staff"));
const StaffMultiStep = lazy(() => import("./pages/staff/multiStep"));
const StaffUnitPage = lazy(() => import("./pages/staff/unit"));
const DecreePage = lazy(() => import("./pages/decree"));
const DecreeFormPage = lazy(() => import("./pages/decree/form"));
const DecreePreviewPage = lazy(() => import("./pages/decree/preview"));
const AssignmnetLetterPage = lazy(() => import("./pages/assignmentLetter"));
const AssignmnetLetterCrudPage = lazy(() =>
  import("./pages/assignmentLetter/form")
);
const AssignmnetLetterPreviewPage = lazy(() =>
  import("./pages/assignmentLetter/preview")
);
const ChangePasswordPage = lazy(() =>
  import("./pages/settings/changePassword")
);
const ReportPayrollPage = lazy(() => import("./pages/report/payroll"));
const ReportRapelPage = lazy(() => import("./pages/report/rapel"));
const SalaryPage = lazy(() => import("./pages/payroll/salary"));
const SalarySlipPage = lazy(() => import("./pages/payroll/salary/slip"));
const SalaryPreviewPage = lazy(() => import("./pages/payroll/salary/preview"));
const DeductionInternPage = lazy(() =>
  import("./pages/payroll/deduction/index")
);
const HeadPage = lazy(() => import("./pages/master/head"));
const SalaryNamePage = lazy(() => import("./pages/master/salaryName"));
const NotificationPage = lazy(() => import("./pages/notification"));
const DeductionInternSpecialPage = lazy(() =>
  import("./pages/payroll/deduction/institution")
);
const DeductionReceivablesStaffPage = lazy(() =>
  import("./pages/payroll/deduction/staff")
);
const DeductionBPJSKesehatan = lazy(() =>
  import("./pages/payroll/deduction/bpjsKesehatan")
);
const DeductionYadapen = lazy(() =>
  import("./pages/payroll/deduction/yadapen")
);
const FormSpecialInternDeductionPage = lazy(() =>
  import("./pages/payroll/deduction/formInstitution")
);
const SubUnitPage = lazy(() => import("./pages/master/subUnit"));
const HonorIncreasePage = lazy(() => import("./pages/payroll/increase"));
const EmploymentHistoryPage = lazy(() =>
  import("./pages/report/employmentHistory")
);
const HistoryDetailPage = lazy(() =>
  import("./pages/report/employmentHistory/detail")
);
const PositionPage = lazy(() => import("./pages/master/position"));
const FormPositionPage = lazy(() => import("./pages/master/position/form"));
const UnitPage = lazy(() => import("./pages/master/unit"));
const FormUnitPage = lazy(() => import("./pages/master/unit/form"));
const ReportStaffPage = lazy(() => import("./pages/report/staff"));
const PresencePage = lazy(() => import("./pages/master/presence"));
const FormPresencePage = lazy(() => import("./pages/master/presence/form"));
const DeductionPPh21Page = lazy(() => import("./pages/payroll/deduction/pph"));
const SignaturePage = lazy(() => import("./pages/master/signature"));
const AssesmentStaffPage = lazy(() => import("./pages/assesment/index"));
const AssesmentListFormPage = lazy(() => import("./pages/assesment/form"));
const AssesmentFormPage = lazy(() => import("./pages/assesment/form/form"));
const AssesmentAnswerDP3Page = lazy(() =>
  import("./pages/assesment/answers/dp3")
);
const DoesntHaveKelmengPage = lazy(() => import("./pages/master/kelmeng"));
const AuditLogPage = lazy(() => import("./pages/settings/log"));
const ProcessNotificationPage = lazy(() =>
  import("./pages/notification/process")
);
const UmkPage = lazy(() => import("./pages/master/umk"));
const RapelFamily = lazy(() => import("./pages/payroll/rapel/family"));
const RapelFunctional = lazy(() => import("./pages/payroll/rapel/functional"));
const RapelHtr = lazy(() => import("./pages/payroll/rapel/htr"));
const RapelStructural = lazy(() => import("./pages/payroll/rapel/structural"));
const RapelSalary = lazy(() => import("./pages/payroll/rapel/salary"));
export default function PageRouteList() {
  const RouteList = [
    {
      path: "/",
      label: "Beranda",
      element: <DashboardPage />,
    },
    {
      path: "/dashboard",
      label: "Beranda",
      element: <DashboardPage />,
    },
    {
      path: "/data-staff",
      label: "Data Pegawai",
      element: <StaffPage />,
    },
    {
      path: "/data-staff-unit",
      label: "Data Staff Unit",
      element: <StaffUnitPage />,
    },
    {
      path: "/data-staff/tambah",
      label: "Tambah Pegawai",
      element: <StaffMultiStep />,
    },
    {
      path: "/data-staff/lihat",
      label: "Detail Pegawai",
      element: <StaffMultiStep isView />,
    },
    {
      path: "/data-staff/edit",
      label: "Edit Pegawai",
      element: <StaffMultiStep isEdit />,
    },
    {
      path: "/surat-keputusan",
      label: "Surat Keputusan",
      element: <DecreePage />,
    },
    {
      path: "/surat-keputusan/lihat",
      label: "Preview Surat Keputusan",
      element: <DecreePreviewPage />,
    },
    {
      path: "/surat-keputusan/tambah",
      label: "Tambah Surat Keputusan",
      element: <DecreeFormPage />,
    },
    {
      path: "/surat-keputusan/edit",
      label: "Edit Surat Keputusan",
      element: <DecreeFormPage isEdit />,
    },
    {
      path: "/surat-tugas",
      label: "Surat Tugas",
      element: <AssignmnetLetterPage />,
    },
    {
      path: "/surat-tugas/lihat",
      label: "Preview Surat Tugas",
      element: <AssignmnetLetterPreviewPage />,
    },
    {
      path: "/surat-tugas/tambah",
      label: "Tambah Surat Tugas",
      element: <AssignmnetLetterCrudPage />,
    },
    {
      path: "/surat-tugas/edit",
      label: "Edit Surat Tugas",
      element: <AssignmnetLetterCrudPage isEdit />,
    },
    {
      path: "/penilaian",
      pathBreadcumb: "/penilaian",
      label: "Penilaian",
      element: <DashboardPage />,
    },
    {
      path: "/penilaian/pegawai",
      label: "Pegawai",
      element: <AssesmentStaffPage />,
    },
    {
      path: "/penilaian/form/list",
      label: "Formulir",
      element: <AssesmentListFormPage />,
    },
    {
      path: "/penilaian/form/edit",
      label: "Edit Formulir",
      element: <AssesmentFormPage />,
    },
    {
      path: "/penilaian/jawaban/dp3",
      label: "Penilaian",
      element: <AssesmentAnswerDP3Page />,
    },
    {
      path: "/master",
      pathBreadcumb: "/master",
      label: "Master",
      element: <DashboardPage />,
    },
    {
      path: "/master/ketua-yayasan",
      label: "Ketua Yayasan",
      element: <HeadPage />,
    },
    {
      path: "/master/nama-tabel-gaji",
      label: "Nama Tabel Gaji",
      element: <SalaryNamePage />,
    },
    {
      path: "/master/sub-unit",
      label: "Sub Unit",
      element: <SubUnitPage />,
    },
    {
      path: "/master/jabatan",
      label: "Jabatan",
      element: <PositionPage />,
    },
    {
      path: "/master/jabatan/edit",
      label: "Edit Jabatan",
      element: <FormPositionPage isEdit />,
    },
    {
      path: "/master/unit",
      label: "Unit",
      element: <UnitPage />,
    },
    {
      path: "/master/unit/edit",
      label: "Edit Unit",
      element: <FormUnitPage isEdit />,
    },
    {
      path: "/master/umk",
      label: "UMK",
      element: <UmkPage />,
    },
    {
      path: "/master/tunjangan-kehadiran",
      label: "Tunjangan Kehadiran",
      element: <PresencePage />,
    },
    {
      path: "/master/tunjangan-kehadiran/form",
      label: "Form Tunjangan Kehadiran",
      element: <FormPresencePage />,
    },
    {
      path: "/master/tidak-dapat-kelmeng",
      label: "Tidak Dapat Kelmeng",
      element: <DoesntHaveKelmengPage />,
    },
    {
      path: "/master/tanda-tangan",
      label: "Tanda Tangan",
      element: <SignaturePage />,
    },
    {
      path: "/data-payroll",
      label: "Data Payroll",
      element: <DashboardPage />,
    },
    {
      path: "/data-payroll/potongan-intern",
      label: "Potongan Lain-lain",
      element: <DeductionInternPage />,
    },
    {
      path: "/data-payroll/potongan-yayasan",
      label: "Iuran Pegawai",
      element: <DeductionInternSpecialPage />,
    },
    {
      path: "/data-payroll/piutang-pegawai",
      label: "Piutang Pegawai",
      element: <DeductionReceivablesStaffPage />,
    },
    {
      path: "/data-payroll/potongan-pph21",
      label: "Potongan PPh 21",
      element: <DeductionPPh21Page />,
    },
    {
      path: "/data-payroll/bpjs-kesehatan",
      label: "Potongan BPJS Kesehatan",
      element: <DeductionBPJSKesehatan />,
    },
    {
      path: "/data-payroll/topup-yadapen",
      label: "Top Up Yadapen",
      element: <DeductionYadapen />,
    },
    {
      path: "/data-payroll/potongan-yayasan/tambah",
      label: "Tambah Potongan Yayasan",
      element: <FormSpecialInternDeductionPage />,
    },
    {
      path: "/data-payroll/potongan-yayasan/edit",
      label: "Edit Potongan Yayasan",
      element: <FormSpecialInternDeductionPage isEdit />,
    },
    {
      path: "/data-payroll/payroll",
      label: "Slip Gaji",
      element: <SalaryPage />,
    },
    {
      path: "/data-payroll/honor-tidak-rutin",
      label: "Honor Tidak Rutin",
      element: <HonorIncreasePage />,
    },
    {
      path: "/rapel",
      label: "Rapel",
      element: <DashboardPage />,
    },
    {
      path: "/rapel/gaji",
      label: "Rapel Gaji",
      element: <RapelSalary />,
    },
    {
      path: "/rapel/tunjangan-keluarga",
      label: "Rapel Tunjangan Keluarga",
      element: <RapelFamily />,
    },
    {
      path: "/rapel/tunjangan-struktural",
      label: "Rapel Tunjangan Struktural",
      element: <RapelStructural />,
    },
    {
      path: "/rapel/tunjangan-fungsional",
      label: "Rapel Tunjangan Fungsional",
      element: <RapelFunctional />,
    },
    {
      path: "/rapel/honor-tidak-rutin",
      label: "Rapel Honor Tidak Rutin",
      element: <RapelHtr />,
    },
    {
      path: "/laporan/slip-gaji",
      label: "Slip Gaji",
      element: <SalarySlipPage />,
    },
    {
      path: "/laporan/preview-payroll",
      label: "Detail Slip Gaji",
      element: <SalaryPreviewPage />,
    },
    {
      path: "/laporan",
      label: "Laporan",
      element: <DashboardPage />,
    },
    {
      path: "/laporan/payroll",
      label: "Gaji Pegawai",
      element: <ReportPayrollPage />,
    },
    {
      path: "/laporan/rapel",
      label: "Rapel",
      element: <ReportRapelPage />,
    },
    {
      path: "/laporan/riwayat-pekerjaan",
      label: "Riwayat Pekerjaan",
      element: <EmploymentHistoryPage />,
    },
    {
      path: "/laporan/detail-riwayat",
      label: "Detail Riwayat Pekerjaan",
      element: <HistoryDetailPage />,
    },
    {
      path: "/laporan/pegawai",
      label: "Pegawai",
      element: <ReportStaffPage />,
    },
    {
      path: "/pengaturan",
      label: "Pengaturan",
      element: <DashboardPage />,
    },
    {
      path: "/pengaturan/ganti-kata-sandi",
      label: "Ganti Kata Sandi",
      element: <ChangePasswordPage />,
    },
    {
      path: "/pengaturan/catatan",
      label: "Audit Log",
      element: <AuditLogPage />,
    },
    {
      path: "/pemberitahuan",
      label: "Pemberitahuan",
      element: <NotificationPage />,
    },
    {
      path: "/pemberitahuan/detail",
      label: "Rincian Pemberitahuan",
      element: <ProcessNotificationPage />,
    },
  ];
  return { RouteList };
}
