import clsx from "clsx";
import { forwardRef } from "react";
import PropTypes from "prop-types";

const Textarea = forwardRef(
  (
    {
      name,
      label,
      rows = 4,
      className,
      errors,
      errorMessage,
      requiredLabel,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    return (
      <div className="input-group" ref={ref}>
        <textarea
          className={clsx(
            `input align-top`,
            className,
            errors
              ? "border-[1.5px] border-red-500"
              : "border-[1.5px] border-gray-400",
            disabled ? `bg-gray-200` : `bg-transparent`,
            disabled &&
              ![null, "", " ", "undefined"].includes(value) &&
              "disabled-value"
          )}
          required
          name={name}
          id={name}
          {...rest}
          disabled={disabled}
          rows={rows}
        ></textarea>
        <label
          className={clsx(
            `input-label`,
            errors ? "text-red-500" : "text-gray-500"
          )}
          htmlFor={name}
        >
          {label || ""}
          {requiredLabel && <span className="text-red-500 ml-1">*</span>}
        </label>
        {errorMessage && (
          <p className="text-red-500 pt-[0.15rem]">{errorMessage}</p>
        )}
      </div>
    );
  }
);

Textarea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  rows: PropTypes.number,
  requiredLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  errorMessage: PropTypes.string,
  rest: PropTypes.any,
};

export default Textarea;
