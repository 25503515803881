import React from "react";
import ReactSelect from "react-select";
import { twMerge } from "tailwind-merge";

const Select = ({
  name,
  label,
  value,
  options = [],
  className,
  required,
  errors,
  border = "border-[var(--color-primary)]",
  padding = "p-[0.825rem]",
  withoutValidation = false,
  disabled = false,
  isMulti = false,
  ...props
}) => {
  const valueContainerStyles = twMerge("gap-1", padding, isMulti && "py-[0.7rem]");
  const indicatorsContainerStyles = "p-1 gap-1";
  const indicatorSeparatorStyles = "bg-gray-300";
  const multiValueLabelStyles = "leading-5";
  const menuStyles = "p-1 mt-2 border border-gray-200 z-[1502!important] bg-white rounded-lg";
  const multiValueStyles = "bg-gray-300 rounded items-center py-1 pl-2 pr-1 gap-1.5";
  const clearIndicatorStyles = "text-gray-400 rounded-md hover:bg-red-50 hover:text-red-800";
  const dropdownIndicatorStyles = "hover:bg-gray-100 text-gray-400 rounded-md hover:text-black";
  const noOptionsMessageStyles =
    "text-gray-900 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm";
  const multiValueRemoveStyles =
    "border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 dark:text-red-500 hover:border-red-300 rounded-md";
  const optionStyles = {
    base: "hover:cursor-pointer px-3 py-2 rounded",
    focus: "bg-gray-100 active:bg-gray-200",
    selected: "after:content-['✔'] after:ml-2 after:text-green-500 text-black",
  };
  return (
    <div className="w-full relative group">
      <ReactSelect
        inputId={name}
        unstyled
        isDisabled={disabled}
        placeholder=""
        options={options}
        isMulti={isMulti}
        value={value}
        styles={{
          input: (base) => ({
            ...base,
            "input:focus": {
              boxShadow: "none",
            },
          }),
          multiValueLabel: (base) => ({
            ...base,
            whiteSpace: "normal",
            overflow: "visible",
          }),
          control: (base) => ({
            ...base,
            transition: "none",
          }),
        }}
        classNames={{
          control: (state) => {
            return twMerge(
              "peer w-full outline-none hover:cursor-pointer border-[1.5px] rounded-md transition disabled:opacity-70 disabled:cursor-not-allowed",
              state.isFocused || value?.length > 0 || Object.keys(value || {}).length > 0
                ? border
                : errors
                ? "border-rose-400"
                : "border-gray-400",
              withoutValidation && "border-transparent",
              disabled ? "bg-gray-200" : "bg-white"
            );
          },
          menu: () => menuStyles,
          multiValue: () => multiValueStyles,
          clearIndicator: () => clearIndicatorStyles,
          valueContainer: () => valueContainerStyles,
          multiValueLabel: () => multiValueLabelStyles,
          multiValueRemove: () => multiValueRemoveStyles,
          dropdownIndicator: () => dropdownIndicatorStyles,
          indicatorSeparator: () => indicatorSeparatorStyles,
          indicatorsContainer: () => indicatorsContainerStyles,
          option: ({ isFocused, isSelected }) =>
            twMerge(
              isFocused && optionStyles.focus,
              isSelected && optionStyles.selected,
              optionStyles.base
            ),
          noOptionsMessage: () => noOptionsMessageStyles,
        }}
        {...props}
      />
      <label
        htmlFor={name}
        className={twMerge(
          `absolute flex items-center gap-1 justify-center px-1 cursor-text origin-[0] left-3 group-focus-within:transform group-focus-within:duration-150 group-focus-within:-top-[0.725rem] group-focus-within:left-[18px] group-focus-within:text-[var(--color-primary)] group-focus-within:scale-90
        `,
          errors ? "text-red-500" : "text-gray-500",
          isMulti && value?.length > 0
            ? `duration-150 transform left-[18px] -top-[0.725rem] text-[var(--color-primary)]`
            : value?.length > 0 || Object.keys(value || {}).length > 0
            ? `duration-150 transform left-[18px] -top-[0.725rem] text-[var(--color-primary)]`
            : "top-[0.900rem] duration-150 transform",
          disabled
            ? value?.length > 0 || Object.keys(value || {}).length > 0
              ? "bg-gradient-to-b from-white to-gray-200"
              : "bg-transparent"
            : "bg-white"
        )}
      >
        {label}
        {required && <span className="text-red-500">*</span>}
      </label>
      {errors && (
        <p className="text-red-500 pt-[0.15rem]">
          {errors?.value ? errors?.value?.message : errors?.message || ""}
        </p>
      )}
    </div>
  );
};

export default Select;
