import clsx from "clsx";

const CInput = ({
  type,
  name,
  label,
  className,
  errors,
  errorMessage,
  requiredLabel,
  disabled = false,
  value,
  onChange,
  ...rest
}) => {
  return (
    <div className="input-group">
      <input
        type={type}
        name={name}
        onChange={onChange}
        id={name}
        className={clsx(
          `input`,
          className,
          errors
            ? "border-[1.5px] border-red-500"
            : "border-[1.5px] border-gray-400",
          disabled ? `bg-gray-200` : `bg-transparent`,
          disabled &&
            ![null, "", " ", "undefined"].includes(value) &&
            "disabled-value"
        )}
        required
        value={value || ""}
        disabled={disabled}
        {...rest}
      />
      <label
        className={clsx(
          `input-label`,
          errors ? "text-red-500" : "text-gray-500"
        )}
        htmlFor={name}
      >
        {label || ""}
        {requiredLabel && <span className="text-red-500 ml-1">*</span>}
      </label>
      {errorMessage && (
        <p className="text-red-500 pt-[0.15rem]">{errorMessage}</p>
      )}
    </div>
  );
};
export default CInput;
