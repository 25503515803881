import clsx from "clsx";
import { forwardRef } from "react";
import PropTypes from "prop-types";

const Date = forwardRef(
  (
    {
      label,
      name,
      className,
      errors,
      errorMessage,
      disabled,
      requiredLabel,
      ...rest
    },
    ref
  ) => {
    return (
      <div className="input-group" ref={ref}>
        <input
          type="date"
          name={name}
          id={name}
          className={clsx(
            "input date",
            className,
            errors
              ? "border-[1.5px] border-red-500"
              : "border-[1.5px] border-gray-400",
            disabled ? "bg-gray-200" : "bg-transparent",
            disabled &&
              ![null, "", " ", "undefined"].includes(value) &&
              "disabled-value"
          )}
          required
          disabled={disabled}
          {...rest}
        />
        <label
          className={clsx(
            `input-label`,
            errors ? "text-red-500" : "text-gray-500"
          )}
          htmlFor={name}
        >
          {label || ""}
          {requiredLabel && <span className="text-red-500 ml-1">*</span>}
        </label>
        {errorMessage && (
          <p className="text-red-600 pt-[0.15rem]">{errorMessage}</p>
        )}
      </div>
    );
  }
);

Date.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  requiredLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.any,
  errorMessage: PropTypes.string,
  rest: PropTypes.any,
};

export default Date;
