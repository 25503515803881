import Spinner from "./spinner";
import React from "react";
import PropTypes from "prop-types";

const LoadingButton = ({
  defaultLabel,
  loading = false,
  labelOnload = "Memproses...",
}) => {
  return (
    <>
      {loading ? (
        <>
          <Spinner />
          <span className="ml-2 text-sm">{labelOnload}</span>
        </>
      ) : (
        <span className="text-sm">{defaultLabel}</span>
      )}
    </>
  );
};
LoadingButton.propTypes = {
  defaultLabel: PropTypes.string,
  loading: PropTypes.bool,
};

export default LoadingButton;
