import { forwardRef } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const Input = forwardRef(
  (
    {
      type,
      name,
      label,
      className,
      errors,
      errorMessage,
      requiredLabel,
      disabled = false,
      value,
      ...rest
    },
    ref
  ) => {
    return (
      <div className="input-group" ref={ref}>
        <input
          type={type}
          name={name}
          id={name}
          className={twMerge(
            `input`,
            className,
            errors
              ? "border-[1.5px] border-red-500"
              : "border-[1.5px] border-gray-400",
            disabled ? `bg-gray-200` : `bg-transparent`,
            disabled &&
              ![null, "", " ", "undefined"].includes(value) &&
              "disabled-value"
          )}
          required
          disabled={disabled}
          {...rest}
        />
        <label
          className={twMerge(
            `input-label`,
            errors
              ? "text-red-500 !-translate-y-1/2 !scale-90 p-[0.4rem] bg-white"
              : "text-gray-500"
          )}
          htmlFor={name}
        >
          {label || ""}
          {requiredLabel && <span className="text-red-500 ml-1">*</span>}
        </label>
        {errorMessage && (
          <p className="text-red-500 pt-[0.15rem]">{errorMessage}</p>
        )}
      </div>
    );
  }
);

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  requiredLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  errors: PropTypes.object,
  value: PropTypes.any,
  errorMessage: PropTypes.string,
  rest: PropTypes.any,
};

export default Input;
