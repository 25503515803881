import React from "react";
import clsx from "clsx";
import './index.css';

export default function Spinner({
    colorStroke = 'stroke-white',
    height = 'h-5',
    width = 'w-5',
}){
    return (
        <>
            
            <svg
                className={clsx(`spinner`, width, height)}
                viewBox="25 25 50 50"
                strokeWidth="5"
            >
                <circle cx="50" cy="50" r="20" className={colorStroke} />
            </svg>
        </>
    )
}