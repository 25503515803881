import { forwardRef } from "react";
import { NumericFormat } from "react-number-format";
import { twMerge } from "tailwind-merge";

const Number = forwardRef(
  (
    {
      name,
      label,
      onChange,
      className,
      errors,
      errorMessage,
      requiredLabel,
      disabled = false,
      value,
      isBlurLabel = false,
      iconRight = <></>,
      autoComplete = "off",
      ...rest
    },
    ref
  ) => {
    return (
      <div className={`input-group${isBlurLabel ? "-blur" : ""}`} ref={ref}>
        <NumericFormat
          name={name}
          id={name}
          decimalScale={2}
          thousandSeparator=","
          onValueChange={onChange}
          onBlur={(e) => {
            let val = e.target.value.trim();
            if (val === "") {
              e.target.value = 0;
            }
          }}
          disabled={disabled}
          autoComplete={autoComplete}
          value={value || ""}
          placeholder=" "
          className={twMerge(
            `input${isBlurLabel ? "-blur" : ""}`,
            errors
              ? `border-[1.5px] border-red-500${
                  isBlurLabel ? " error-blur" : ""
                }`
              : "border-[1.5px] border-gray-400",
            disabled ? `bg-gray-200` : `bg-transparent`,
            disabled &&
              ![null, "", " ", "undefined"].includes(value) &&
              "disabled-value",
            className
          )}
          onFocus={(e) => e.target.select()}
          {...rest}
        />
        <label
          className={twMerge(
            `input-label${isBlurLabel ? "-blur" : ""}`,
            errors
              ? `text-red-500${isBlurLabel ? " error-blur" : ""}`
              : "text-gray-500"
          )}
          htmlFor={name}
        >
          {label || ""}
          {requiredLabel && <span className="text-red-500 ml-1">*</span>}
        </label>
        {iconRight}
        {errorMessage && (
          <p className="text-red-500 pt-[0.15rem]">{errorMessage}</p>
        )}
      </div>
    );
  }
);
export default Number;
